import { Controller } from "stimulus"
import toastr from "toastr";

export default class extends Controller {
  static targets = ["phone"]

  connect() {
  }

  copy(e) {
    toastr.options.closeDuration = 300;
    var copyText = e.target.parentNode.dataset.phone;
    var textArea = document.createElement("textarea");
    textArea.value = copyText;
    document.body.appendChild(textArea);
    textArea.select();
    if (document.execCommand("Copy")) {
      toastr.info('已复制到张贴板')
      textArea.remove();
    }
  }
}